
import React from 'react'
import Layout from '../components/layout'

export default () => (
  <Layout>

<div className="site-container thank-you">
    <div className="container text-center">
    <h1>Thank you!</h1>
    <p>Your form has been subimitted we will contact you as soon as possible.</p>
    </div>
    </div>
  </Layout>
)


